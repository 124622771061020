<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col lg="12" md="12">
        <h5 style="color:#5f58c4">Welcome, {{ this.driverName }}</h5>
        <hr class="v-divider v-theme--light my-1" aria-orientation="horizontal" role="separator">
      </b-col>
    </b-row>
    <div class="row">
      <div class="col-6 col-md-3 col-sm-6 col-12 mb-3" style="padding: 0 5px;">
        <router-link to="/time-clock" style="color:#000;">
          <div class="card h-100 mb-4 box-shadow text-black bg-default dashboard_card">
            <div class="card-body d-flex align-items-center justify-content-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-clock" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#5f58c4" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="12" cy="12" r="9" />
                <polyline points="12 7 12 12 15 15" />
              </svg>
              <br> Time Clock
            </div>
          </div>
        </router-link>
      </div>

      <div class="col-6 col-md-3 col-sm-6 col-12 mb-3" style="padding: 0 5px;">
        <router-link to="/vehicle/inspection" style="color:#000;">
          <div class="card h-100 mb-4 box-shadow text-black bg-default dashboard_card">
            <div class="card-body d-flex align-items-center justify-content-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-shield" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#5f58c4" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3" />
              </svg>
              <br> Inspections
            </div>
          </div>
        </router-link>
      </div>

      <div class="col-6 col-md-3 col-sm-6 col-12 mb-3" style="padding: 0 5px;">
        <router-link to="/vehicle_documents" style="color:#000;">
          <div class="card h-100 mb-4 box-shadow text-black bg-default dashboard_card">
            <div class="card-body d-flex align-items-center justify-content-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-archive" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#5f58c4" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <rect x="3" y="4" width="18" height="4" rx="2" />
                <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />
                <line x1="10" y1="12" x2="14" y2="12" />
              </svg>
              <br> Documents
            </div>
          </div>
        </router-link>
      </div>

      <div class="col-6 col-md-3 col-sm-6 col-12 mb-3" style="padding: 0 5px;">
        <router-link to="/earnings" style="color:#000;">
          <div class="card h-100 mb-4 box-shadow text-black bg-default dashboard_card">
            <div class="card-body d-flex align-items-center justify-content-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-currency-dollar" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#5f58c4" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M16.7 8a3 3 0 0 0 -2.7 -2h-4a3 3 0 0 0 0 6h4a3 3 0 0 1 0 6h-4a3 3 0 0 1 -2.7 -2" />
                <path d="M12 3v3m0 12v3" />
              </svg>
              <br> Driver Earnings
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <b-modal
      size="lg"
      id="modal-signature"
      centered
      title="Add Signature"
      ok-only
      ok-title="Cancel"
      ref="modal-signature"
      no-close-on-backdrop
      v-bind:hide-footer="true"
    >
      <div ref="canvasWrapper" class="canvas-wrapper">
        <vueSignature ref="signature1" :sigOption="Sioption"></vueSignature>
      </div>
      <div>
        <b-button v-on:click="save" type="button" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" style="margin-left: 15px;margin-right: 5px;">Save</b-button>
        <b-button v-on:click="clear" type="button" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-danger" style="margin-right: 5px;">Clear</b-button>
        <b-button v-on:click="undo" type="button" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary">Undo</b-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
import vueSignature from "vue-signature"
import { serverUri } from "@/config";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    vueSignature,
    ToastificationContent


  },
  data() {
    return {
      data: {},
      userData: getUserData(),
      companyName: getUserData().company_name.toUpperCase(),
      driverName: getUserData().nick_name == '' ? '' : getUserData().nick_name.toUpperCase(),
      Sioption:{
				penColor:"rgb(0, 0, 0)",
				backgroundColor:"rgb(255,255,255)"
			},
			disabled:false,
    };
  },
  created() {
    // console.log(userData);
    // data
    // this.$http.get("/analytics/data").then((response) => {
    //   this.data = response.data;

    //   console.log(this.data);

    //   this.$refs["modal-signature"].show();

    // });

    this.$http.get(`${serverUri}/drivers/details/${this.userData.driver_id}`).then((response) => {
      this.data = response.data;

      if(this.data[0].signature == null || this.data[0].signature == '')
      {
        this.$refs["modal-signature"].show();
      }

    });
  },
  methods: {
    showToast(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: text,
          variant,
        },
      });
    },
    save(){
			var _this = this;
      let Sign = "xxxxx";
			//var Sign = _this.$refs['signature1'].save()
			

      this.$http.post(`${serverUri}/drivers/updateDriverSign`,
      {
        driver_id:this.userData.driver_id,
        signature:Sign
      }).then((response) => {

      if(response.data.status)
      {
        this.showToast(
              "success",
              "Success",
              response.data.message
            );
        this.$refs["modal-signature"].hide();
      }

    });

		},
		clear(){
			var _this = this;
			_this.$refs['signature1'].clear();
		},
		undo(){
			var _this = this;
			_this.$refs['signature1'].undo();
		},

  },
};

</script>

<style>
.box {
   background: #fff;
   padding-bottom: 100%;
}

.col-lg-2, .col-md-3, .col-xs-6{
    margin-top: 10px !important;
}
svg.feather,.icon-tabler {
  width:100%;
}
hr {
    border-top: 1px solid #bdbdbd;
    width:100%;
}
.dashboard_card {
  color: #5f58c4;
}
#modal-signature___BV_modal_content_ .close {
  display:none;
}
.canvas-wrapper {
  width: 90%;
  height: 90%;
  margin: 0 auto; /* Center the canvas horizontally */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
